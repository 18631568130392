<script>
// import Transaction from "@/components/widgets/transaction";
import appConfig from "@/app.config";

/**
 * Products-order component
 */
export default {
  page: {
    title: "Orders",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Order Details",
      status_histories: [],
      tot_pages: 0,
      page: 1,
      limit: 20,
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
    };
  },
  methods: {
    get(page) {
      this.http
        .post("orders/vendor/status-history/paginate", {
          page: page,
          limit: this.limit,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          if (res.status) {
            this.status_histories = res.data;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            console.log(44,this.status_histories)
          }
        });
    },
  },
  created() {
    this.get(1);
    this.socket.on("orders:update_status", () => {
      this.get(this.page);
    });
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th>#</th>
                  <!-- <th>
                    {{ $t("order_deatiles.tabs.status_history.table.user") }}
                  </th> -->
                  <th>
                    {{ $t("order_deatiles.tabs.status_history.table.from") }}
                  </th>
                  <th>
                    {{ $t("order_deatiles.tabs.status_history.table.to") }}
                  </th>
                  <th>
                    {{ $t("order_deatiles.tabs.status_history.table.date") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(status, index) in status_histories" :key="status">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? status?.status_from_orders_status?.status_ar
                        : status?.status_from_orders_status?.status
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? status?.status_to_orders_status?.status_ar
                        : status?.status_to_orders_status?.status
                    }}
                  </td>
                  <td>
                    {{ status?.created?.split("T")[0] }}
                    {{ status?.created?.split("T")[1]?.split(".")[0] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul class="pagination pagination-rounded justify-content-end mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
