<script>
// import Transaction from "@/components/widgets/transaction";
import appConfig from "@/app.config";

/**
 * Products-order component
 */
export default {
  page: {
    title: "Orders",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Order Details",
      order: {},
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
    };
  },
  methods: {
    getOrderDriver() {
      this.http.get("orders/driver/" + this.$route.params.id).then((res) => {
        if (res.status) {
          this.order = res.data;

          console.log(35,this.order)
        }
      });
    },
  },
  created() {
    this.getOrderDriver();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th>
                    {{ $t("order_deatiles.tabs.customer_info.table.name") }}
                  </th>
                  <th>
                    {{ $t("order_deatiles.tabs.customer_info.table.email") }}
                  </th>
                  <th>
                    {{ $t("order_deatiles.tabs.customer_info.table.id_card") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ order?.order?.driver?.name }}</td>
                  <td>{{ order?.order?.driver?.user?.email }}</td>
                  <td>
                    <img
                      v-if="order?.order?.driver?.id_image"
                      :src="
                        $store.state?.auth?.mediaUrl +
                        order?.order?.driver?.id_image?.split('public')[1]
                      "
                      style="width: 64px; height: 64px"
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
