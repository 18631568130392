<script>
// import OrderInfo from "./OrderInfo/index";
// import CustomerInfo from "./CustomerInfo/index";
// import Transaction from "@/components/widgets/transaction";
import appConfig from "@/app.config";

/**
 * Products-order component
 */
export default {
  page: {
    title: "Orders",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      loading: false,
      order: {},
      total: 0,
      orderId: null,
      status_id: null,
      ordersStatus: [],
      orderAdds: [],
      selectedProduct: {
        id: null,
        qty: 0,
        product_qty: 0,
      },
      reason: "",
    };
  },
  methods: {
    bindProduct(product) {
      this.selectedProduct.qty = product?.qty;
      this.selectedProduct.product_qty = product?.qty;
      this.selectedProduct.id = product?.id;
      console.log(this.selectedProduct);
    },
    returnOrder() {
      const data = { reason: this.reason, ...this.selectedProduct };
      console.log(data);
      this.reason = "";
    },
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    getOrderDetails() {
      this.http.get("orders/" + this.$route.params.id).then((res) => {
        if (res.status) {
          this.orderAdds = [];
          this.order = res.data;
          this.status_id = this.order?.status_id;
          this.total = this.order?.order_price;
          this.order?.vendor_order_details?.forEach((order) => {
            if (order?.vendor_order_product_adds.length > 0) {
              order?.vendor_order_product_adds?.forEach((add) => {
                this.orderAdds.push({ ...add.add, price: add?.price });
              });
            }
          });

          console.log(80, this.order?.vendor_order_details);
        }
      });
    },
    changeOrderStatus() {
      // this.loading = true;
      // this.http
      //   .put("orders", this.order.id, { status_id: this.status_id })
      //   .then((res) => {
      //     this.loading = false;
      //     if (res.status) {
      //       if (this.status_id == 6) {
      //         this.socket.emit("Waitting Offers", this.order?.id);
      //         console.log("done");
      //       }
      //       if (this.$i18n.locale == "ar") {
      //         this.responseAlert("تم تعديل الحالة بنجاح", " تم ", "success");
      //       } else {
      //         this.responseAlert(
      //           "Status has been edited successfully",
      //           "done",
      //           "success"
      //         );
      //       }
      //       this.getOrderDetails();
      //     } else {
      //       if (this.$i18n.locale == "ar") {
      //         this.responseAlert(
      //           "حدث خطأ اثناء تعديل الحالة",
      //           "خطأ",
      //           "warning"
      //         );
      //       } else {
      //         this.responseAlert("something went wrong", "error", "warning");
      //       }
      //     }
      //   });
      this.order.status_id = this.status_id;
      this.socket.emit("order_status_change", this.order);
    },
    getOrdersStatus() {
      this.http.get("orders-status").then((res) => {
        if (res.status) {
          this.ordersStatus = [];
          res.data.forEach((status) => {
            console.log(status);
            if (status?.id == 5 || status?.id == 6 || status?.id == 13) {
              this.ordersStatus.push(status);
            }
          });
        }
      });
    },
    openDetails(product) {
      let id;
      if (product?.product_id) {
        id = product?.product_id;
        this.$router.push(`/products/product/${id}`);
      } else {
        id = product?.option?.product_id;
        this.$router.push(`/products/product/${id}`);
      }
    },
    goToProduct(id) {
      this.$router.push(`/products/product/${id}`);
    },
  },
  mounted() {
    this.getOrderDetails();
    this.getOrdersStatus();
    this.status_id = this.order?.status_id;
    this.orderId = parseInt(this.$route.params.id);
    this.socket.on("orders:update_status", (order) => {
      this.getOrderDetails();
      console.log("goat data", order);
      if (this.$i18n.locale == "ar") {
        this.responseAlert("تم تعديل الحالة بنجاح", " تم ", "success");
      } else {
        this.responseAlert(
          "Status has been edited successfully",
          "done",
          "success"
        );
      }
    });
  },
};
</script>

<template>
  <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="invoice-title">
            <h4
              :class="$i18n.locale == 'ar' ? 'float-start' : 'float-end'"
              class="font-size-16"
            >
              {{ $t("orders.order") }} # {{ $route.params.id }}
            </h4>
            <div class="mb-4">
              <img
                :src="
                  $store.state?.auth?.mediaUrl +
                  order?.user?.customers[0]?.image?.split('public')[1]
                "
                alt="logo"
                height="20"
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6">
              <address>
                <strong>{{ $t("orders.bill") }}:</strong>
                <br />
                {{ order?.order?.user?.name }}
                {{
                  `${order?.order?.user?.customers[0]?.first_name} ${order?.order?.user?.customers[0]?.last_name}`
                }}
              </address>
            </div>
            <div
              class="col-6"
              :class="$i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'"
            >
              <address>
                <strong>{{ $t("orders.shipping") }}:</strong>
                <br />{{ order?.order?.user_address?.address }}
              </address>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mt-3">
              <address>
                <strong>{{ $t("orders.payment") }}:</strong>
                <br />{{ order?.payment_method?.name }} <br />{{
                  order?.user?.customers[0]?.email
                }}
              </address>
            </div>
            <div
              class="col-6 mt-3"
              :class="$i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'"
            >
              <address>
                <strong>{{ $t("orders.order_date") }}:</strong>
                <br />{{ order?.created?.split("T")[0] }}
                <br />
                <strong>{{ $t("orders.order_status") }}:</strong>
                <br />{{
                  $i18n.locale == "ar"
                    ? order?.order?.status?.status_ar
                    : order?.order?.status?.status
                }}
                <br />
              </address>
            </div>
          </div>
          <div class="p-2 mt-3">
            <h3 class="font-size-16">{{ $t("orders.summary") }}</h3>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="zig-color">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{{ $t("orders.products_order") }}</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th style="width: 70px">{{ $t("orders.no") }}</th>
                  <th>{{ $t("orders.item") }}</th>
                  <th>{{ $t("orders.attribute") }}</th>
                  <th>{{ $t("orders.value") }}</th>
                  <th>{{ $t("orders.qty") }}</th>
                  <th
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    {{ $t("orders.price") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="active-order"
                  @click="openDetails(product)"
                  :key="product"
                  v-for="(product, index) in order?.vendor_order_details"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      product?.product
                        ? product?.product?.name
                        : product?.option?.product?.name
                    }}
                  </td>
                  <td>{{ product?.option ? product?.option?.name : "-" }}</td>
                  <td>{{ product?.option ? product?.option?.value : "-" }}</td>
                  <td>{{ product?.qty }}</td>
                  <td
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    {{ parseFloat(product?.price).toLocaleString() }}
                  </td>
                </tr>
                <tr class="zig-color">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{{ $t("orders.adds_order") }}</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th style="width: 70px">{{ $t("orders.no") }}</th>
                  <th>{{ $t("orders.item") }}</th>
                  <th>{{ $t("orders.add") }}</th>
                  <th></th>
                  <th>{{ $t("orders.qty") }}</th>
                  <th
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    {{ $t("orders.price") }}
                  </th>
                </tr>
                <tr
                  class="active-order"
                  @click="goToProduct(add?.product?.id)"
                  v-for="(add, index) in orderAdds"
                  :key="add"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ add?.product?.name }}</td>
                  <td>{{ $i18n.locale == "ar" ? add?.name_ar : add?.name }}</td>
                  <td></td>
                  <td>{{ 1 }}</td>
                  <td
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    {{ parseFloat(add?.price).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    class="border-0"
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    <strong>{{ $t("orders.total") }}</strong>
                  </td>
                  <td
                    class="border-0"
                    :class="
                      $i18n.locale == 'ar' ? 'text-sm-start' : 'text-sm-end'
                    "
                  >
                    <h4 class="m-0">
                      {{ parseFloat(total).toLocaleString() }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-print-none">
            <div :class="$i18n.locale == 'ar' ? 'float-start' : 'float-end'">
              <a
                style="margin-inline-end: 4px"
                href="javascript:window.print()"
                class="btn btn-success waves-effect waves-light"
                ><i class="fa fa-print"></i
              ></a>
              <button
                data-bs-toggle="modal"
                data-bs-target="#statusModal"
                href="#"
                class="btn btn-primary w-md waves-effect waves-light"
              >
                {{ $t("orders.change") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- start status modal -->

  <div
    class="modal fade"
    id="statusModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("orders.change_order") }} #{{ order?.id }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <div class="row p-2">
              <label class="inline">{{ $t("orders.change_to") }}</label>
              <select class="mb-3 form-select" v-model="status_id">
                <option
                  v-for="status in ordersStatus"
                  :key="status"
                  :selected="status.id == status_id"
                  :value="status.id"
                >
                  {{ $i18n.locale == "ar" ? status.status_ar : status.status }}
                </option>
              </select>
            </div>
          </div>

          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              v-if="!loading"
              @click="changeOrderStatus()"
              class="btn btn-success"
            >
              {{ $t("orders.change") }}
            </button>
            <button
              v-if="loading"
              disabled
              type="submit"
              class="btn btn-success"
              style="margin-inline-end: 8px"
            >
              <b-spinner small></b-spinner>
              <span class="sr-only">loading ...</span>
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-secondary"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end status modal -->

  <!-- start return modal -->
  <div
    class="modal fade"
    id="returnModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("orders.return") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <div class="mb-3">
              <label for="" class="form-label">{{ $t("orders.qty") }}</label>
              <input
                v-model="selectedProduct.qty"
                type="number"
                :min="1"
                :max="selectedProduct?.product_qty"
                class="form-control"
                :class="
                  selectedProduct?.qty <= 0 ||
                  selectedProduct?.qty > selectedProduct?.product_qty
                    ? 'error'
                    : ''
                "
              />
              <p
                class="mt-1 text-danger"
                v-if="
                  selectedProduct?.qty <= 0 ||
                  selectedProduct?.qty > selectedProduct?.product_qty
                "
              >
                {{ $t("orders.return_qty_validation") }}
                {{ selectedProduct?.product_qty }}
              </p>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{ $t("orders.reason") }}</label>
              <textarea
                v-model="reason"
                class="form-control"
                cols="10"
                rows="5"
              ></textarea>
            </div>
          </div>

          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              :disabled="
                selectedProduct?.qty <= 0 ||
                selectedProduct?.qty > selectedProduct?.product_qty
              "
              @click="returnOrder"
              class="btn btn-success"
            >
              {{ $t("orders.return") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-secondary"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end return modal -->
</template>

<style scoped>
* {
  font-family: "MyCustomFont" !important;
}
.error {
  border-color: red;
}
@media print {
  .no-print {
    display: none;
  }
}
.active-order:hover {
  cursor: pointer;
  background: rgb(222, 222, 226);
}
.active-order {
  padding: 1rem !important;
}
td,
th {
  padding: 1rem;
}
.zig-color {
  background: rgb(222, 222, 226);
}
</style>
