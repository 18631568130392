<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import OrderInfo from "./OrderInfo/index.vue";
import OrderTrack from "./OrderTrack/index.vue";
import DriverInfo from "./DriverInfo/index.vue";
import StatusHistory from "./StatusHistory/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    OrderInfo,
    OrderTrack,
    DriverInfo,
    StatusHistory,
  },
  data() {
    return {
      title_en: "Details",
      title: "تفاصيل ",
      orderId: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {},
  created() {
    this.orderId = parseInt(this.$route.params.id);
  },
  mounted() {
    console.log({ currentRouteName: this.currentRouteName });
  },
};
</script>

<template>
  <Layout class="no-print">
    <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link
            :to="
              currentRouteName == 'new-order-deatiles'
                ? `/new-orders`
                : `/accepted-orders`
            "
          >
            <h4 class="m-0">
              {{ $t("menu.menuitems.orders.text") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $i18n.locale == "ar"
                ? `${title} ${orderId}#`
                : `#${orderId} ${title_en}`
            }}
          </div>
        </div>
      </div>
    </div>
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start departments -->
      <b-tab active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("orders.tabs.order") }}
          </span>
        </template>
        <OrderInfo />
      </b-tab>
      <!-- end departments -->

      <!-- start designations -->
      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("orders.tabs.driver") }}
          </span>
        </template>
        <DriverInfo  />
      </b-tab>
      <!-- end designations -->

      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("orders.tabs.tracking") }}
          </span>
        </template>
        <OrderTrack />
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("orders.tabs.status_history") }}
          </span>
        </template>
        <StatusHistory />
      </b-tab>
    </b-tabs>
  </Layout>
  <div class="print">
    <OrderInfo />
  </div>
</template>
<style>
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
